import React, { useState } from 'react'

import { useAuth, useSignIn } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup';

import { OutlinedButtons } from '../../Shared/Button/OutlinedButtons'
import CustomInput from '../../Shared/Input/CustomInput'

interface ForgotPasswordFormProps {
    onNextStep: () => void
}

const emailSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('*Required')
  });
  
  const resetSchema = yup.object().shape({
    resetCode: yup.string().required('*Required'),
    password: yup.string().required('*Required')
  });
  

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({onNextStep}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')
    const [successfulCreation, setSuccessfulCreation] = useState(false)
    const [secondFactor, setSecondFactor] = useState(false)
    

    const navigate = useNavigate()
    const { isSignedIn } = useAuth()
    const { isLoaded, signIn, setActive } = useSignIn()
    
    const {
        register,
        setError,
        handleSubmit,
        formState: { errors }
      } = useForm({ mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(successfulCreation ? resetSchema : emailSchema)
      });

    if (!isLoaded) {
        return null
    }

    if (isSignedIn) {
        navigate('/login')
    }

    const onSubmit = async (data: any) => {
        if (!successfulCreation) {
          await create(data.email);
        } else {
          await reset(data.resetCode, data.password);
        }
      };
    

    async function create(email: string) {
        await signIn
            ?.create({
                strategy: 'reset_password_email_code' as any,
                identifier: email
            })
            .then(() => {
                setSuccessfulCreation(true)
                setError('email', {
                    message: ''
                })
                onNextStep()
            })
            .catch((err) => {
                setError('email', {
                    message: err.errors[0].longMessage
                })
            })
    }

    async function reset(code: string, password: string) {
        await signIn
            ?.attemptFirstFactor({
                strategy: 'reset_password_email_code' as any,
                code,
                password
            })
            .then((result) => {
                if (result.status === 'needs_second_factor') {
                    setSecondFactor(true)
                } else if (result.status === 'complete') {
                    setActive({ session: result.createdSessionId })
                }
            })
            .catch((err) => {
                if(err.errors[0].message === "is incorrect"){
                    setError('resetCode', {
                        message: err.errors[0].longMessage
                    })
                } else if(err.errors[0].message === "expired"){
                    setError('resetCode', {
                        message: err.errors[0].longMessage
                    })
                }else{
                    setError('password', {
                        message: err.errors[0].longMessage
                    })
                }
            })
    }

    return (
        <Box sx={{ textAlign: 'center', px: 7, mt: 4 }}>
            <Box sx={{ width: '100', textAlign: 'center', mb: 1 }}>
                {!successfulCreation && (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1em'
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box>
                            <CustomInput
                                name="email"
                                placeholder="Enter Email"
                                register={register}
                                error={errors?.email?.message}
                                value={email}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Box>
                        <Grid>
                            <OutlinedButtons
                                width='277px'
                                bgColor="primary.light"
                                text="Send Reset Code"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Grid>
                    </form>
                )}

                {successfulCreation && (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1em'
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box>
                            <CustomInput
                                placeholder="Enter reset code"
                                showLabel
                                value={code}
                                type="text"
                                name="resetCode"
                                onChange={(e) => setCode(e.target.value)}
                                register={register}
                                error={errors?.resetCode?.message}
                                xs={12}
                                md={12}
                            />
                        </Box>
                        <Box>
                            <CustomInput
                                placeholder="Enter New Password"
                                showLabel
                                type="password"
                                value={password}
                                error={errors?.password?.message}
                                onChange={(e) => setPassword(e.target.value)}
                                register={register}
                                name="password"
                                showPasswordToggle
                                xs={12}
                                md={12}
                            />
                        </Box>
                        <Grid>
                            <OutlinedButtons
                                width='277px'
                                onClick={handleSubmit(onSubmit)}
                                bgColor="primary.light"
                                text="Reset"
                            />
                        </Grid>
                    </form>
                )}
                {secondFactor && (
                    <p>2FA is required, but this UI does not handle that</p>
                )}
            </Box>
        </Box>
    )
}

export default ForgotPasswordForm
