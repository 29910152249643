import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, Grid, Typography, Tooltip, IconButton} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import moment from 'moment';

import EventCardIcon from '../../../assets/event_card_birthday_icon2.jpg';
import {eventService} from '../../../services/event.service';
import ArrowForward from '../ArrowForward';
import CustomLoadingButton from '../Button/CustomLoadingButton';

const EventDetailsCard = ({
  eventType,
  eventName,
  eventImageUrl,
  eventDate,
  redirectUrl,
  eventStatus,
  isDelete,
  eventPublished,
  eventId,
  handleGetFilteredEvents,
  showEventDate=true
}: any) => {
  const [open, setOpen] = React.useState(false);
  const [isOverflowed, setIsOverflow] = React.useState(false);
  const textElementRef = React.useRef<HTMLHeadingElement | null>(null);

  React.useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      );
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteEvent = async () => {
    try {
      if (eventId) {
        await eventService.deleteEvent(eventId);
        setOpen(false);
        await handleGetFilteredEvents(eventId);
      }
    } catch (error) {
      throw new Error('Failed to delete event');
    }
  };

  return (
    <Grid item xs={6} md={3} lg={2} xl={2}>
      <Card>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '140px'
          }}
        >
          <img
            alt="Event preview"
            style={{
              width: '100%',
              height: '140px'
            }}
            src={eventImageUrl || EventCardIcon}
          />
          {eventStatus === 'Active' && isDelete && (
            <IconButton
              aria-label="more"
              aria-controls="event-menu"
              aria-haspopup="true"
              onClick={handleClickOpen}
              sx={{
                position: 'absolute',
                top: 4,
                right: 4,
                backgroundColor: 'custom.blueGray'
              }}
            >
              <DeleteIcon style={{ fontSize: 14, cursor: 'pointer' }} />
            </IconButton>
          )}
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to delete ${eventName}'s ${eventType}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomLoadingButton onClick={handleClose} width="110px" sx={{ marginTop: '10px' }} name="Disagree" />
              <CustomLoadingButton onClick={handleDeleteEvent} width="110px" sx={{ marginTop: '10px' }} name="Agree" />
            </DialogActions>
          </Dialog>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid display="flex" sx={{ p: 1 }}>
            <Grid item xs={10} md={10}>
              <Tooltip title={`${eventName}'s`} arrow disableHoverListener={!isOverflowed}>
                <Typography
                  ref={textElementRef}
                  gutterBottom
                  component="h6"
                  sx={{
                    font: 'normal normal 600 13px/16px Inter',
                    color: 'custom.gray',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block'
                  }}
                >
                  <span>{eventName}&apos;s</span>
                </Typography>
              </Tooltip>
              <Typography
                gutterBottom
                component="h6"
                sx={{
                  font: 'normal normal 600 13px/16px Inter',
                  color: 'custom.gray',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1 !important',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {eventType}
              </Typography>
              {showEventDate && eventDate && eventStatus !== 'Published' && (
                <Typography sx={{ font: 'normal normal normal 11px/14px Inter', color: 'secondary.main' }}>
                  {moment(eventDate).format('MM-DD-YYYY')}
                </Typography>
              )}
              {eventStatus === 'Published' && (
                <Typography sx={{ font: 'normal normal normal 11px/14px Inter', color: 'secondary.main' }}>
                  Event Published
                </Typography>
              )}
            </Grid>
            <ArrowForward redirectUrl={redirectUrl} eventStatus={eventStatus} eventPublished={eventPublished} />
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

export default EventDetailsCard;
