export const baseUrlToFile = (base64Data: string, fileName: string): File => {
    const [header, base64String] = base64Data.split(',');
    const mimeType = header.match(/:(.*?);/)?.[1]; 
    const byteString = atob(base64String); 
    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i+=1) {
        byteArray[i] = byteString.charCodeAt(i);
    }

    return new File([byteArray], fileName, { type: mimeType || 'image/png' });
};


export const getCroppedImg = async (
    imageSrc: string,
    croppedAreaPixels: { width: number; height: number; x: number; y: number },
    format = 'image/jpeg',
    quality = 0.8,
    maxWidth = 1000, 
    maxHeight = 1000  
  ): Promise<string> => {
    try {
      const image = await createImage(imageSrc);
  
      let {width} = croppedAreaPixels;
      let {height} = croppedAreaPixels;
  
      if (width > maxWidth || height > maxHeight) {
        const ratio = Math.min(maxWidth / width, maxHeight / height);
        width = Math.round(width * ratio);
        height = Math.round(height * ratio);
      }
  
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }
  
      canvas.width = width;
      canvas.height = height;
  
      ctx.drawImage(
        image,
        croppedAreaPixels.x, 
        croppedAreaPixels.y, 
        croppedAreaPixels.width, 
        croppedAreaPixels.height, 
        0, 
        0, 
        width, 
        height 
      );
  
      return canvas.toDataURL(format, quality);
    } catch (error) {
      throw new Error('Could not crop the image');
    }
  };
  
  const createImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = url;
  
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
  



