import { useEffect, useState } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'

import EventDetailsCard from '../../components/Shared/Cards/EventDetailsCard';
import Loader from '../../components/Shared/Loader'
import {eventService} from '../../services/event.service'

export default function MyInvites() {
    const { isSignedIn } = useAuth()
    const { user } = useUser()
    const [events, setInvites] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    const getMyInvites = async () => {
        await eventService.getUserInvites(
            user?.primaryEmailAddress?.emailAddress as string
        )
            .then((response) => {
                setInvites(response.data)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (isSignedIn) {
            getMyInvites()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    return (
        <Container sx={{ width: '100%', mt: 2 }}>
            {isLoading && isSignedIn && (
                <>
                    <Loader />
                </>
            )}
            {!isLoading && isSignedIn && (
                <Box sx={{ width: '100%', my: 3 }}>
                    <Grid container spacing={2}>
                        {events?.map((event: any) => (
                            <EventDetailsCard
                                key={event?.event_id}
                                eventName={event?.name}
                                eventType={event?.event_type}
                                eventImageUrl={event?.event_image_url}
                                eventDate={event?.event_date}
                                redirectUrl={`/upload-images/${event?.event_id}`}
                                eventStatus={event?.status}
                            />
                        ))}

                        {events.length === 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    mt: 25,
                                    mx: 'auto',
                                    width: '400',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                        font: 'normal normal normal 13px/16px Inter',
                                        color: '#8D8D8D'
                                    }}
                                >
                                    No Invites to show.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
            )}
        </Container>
    )
}
