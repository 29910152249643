/* eslint-disable max-lines-per-function */
import { useState, useEffect, useRef } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material'

import { MediaDisplay } from './MediaViewer/MediaDisplay'
import {eventService} from '../../../services/event.service'

function ManageInviteeUploads(props: any) {
    const [media, setMedia] = useState<any>([])
    const [selectedInvitee, setSelectedInvitee] = useState<any>()
    const [showMedia, setShowMedia] = useState<any>(false)
    const [isOverflowed, setIsOverflowed] = useState<Record<string, boolean>>({});
    const textRefs = useRef<Record<string, Record<'email' | 'username', HTMLSpanElement  | null>>>({});

    const checkOverflow = (id: string, field: 'email' | 'username') => {
        const element = textRefs.current[id]?.[field];
         if (element) {
            return element.scrollWidth > element.clientWidth;
         }
         return false;
        };
        useEffect(() => {
            const updateOverflowStatus = () => {
                const statuses: Record<string, boolean> = {};
                Object.keys(textRefs.current).forEach((id) => {
                    ['email', 'username'].forEach((field) => {
                        const key = field as 'email' | 'username'; 
                        if (textRefs.current[id]?.[key]) {
                            statuses[`${id}-${field}`] = checkOverflow(id, field as 'email' | 'username');
                        }
                    });
                });
                 setIsOverflowed(statuses);
                };
          const resizeObserver = new ResizeObserver(() => {
          updateOverflowStatus();
        });
        Object.values(textRefs.current).forEach((fields) => {
            Object.values(fields).forEach((element) => {
                if (element) {
                    resizeObserver.observe(element);
                }
            });
        });
        return () => {
          resizeObserver.disconnect();
        };
        }, []);

        const getMedia = async (invitee: any) => {
            setSelectedInvitee(invitee)
            await eventService.getMediaByInvitee(
                props?.eventInvites.event_id,
                invitee.email
            )
                .then((response: any) => {
                    setMedia(response.data)
                    setShowMedia(true)
                })
                .catch(() => {})
        }

    return (
        <>
            {!showMedia ? (
               props?.eventInvites?.invites?.map((invitee: any) => (
                    <Box sx={{ width: '100%', mt: 1 }} key={invitee?.email}>
                        <Grid
                            container
                            component="main"
                            direction="row"
                            alignItems="center"
                            sx={{
                                backgroundColor: 'white',
                                width: '100%',
                                display: 'flex',
                                p: 1,
                                borderRadius: '4px',
                                boxShadow: '0px 0px 30px #0000000D',
                                border: '1px solid',
                                borderColor: 'secondary.contrastText'
                            }}
                        >
                            <Grid item xs={5} sm={4}>
                            <Tooltip
                                title={isOverflowed[`${invitee.email}-username`] ? invitee.username : ""}
                                arrow
                                disableHoverListener={!isOverflowed[`${invitee.email}-username`]}
                            >
                                    <Typography
                                        component="span"
                                        ref={(el) => {
                                            if (!textRefs.current[invitee.email]){
                                                textRefs.current[invitee.email] = { email: null, username: null };
                                            } 
                                            textRefs.current[invitee.email].username = el;
                                        }}
                                        variant="body1"
                                        sx={{
                                            fontSize: '12px',
                                            color: 'custom.dark',
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            width: '90%'  
                                        }}
                                    >
                                        {invitee?.username ?? invitee?.email}
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                md={7}
                                justifyItems="right"
                                justifyContent="right"
                            >
                                <Tooltip
                                    title={isOverflowed[`${invitee.email}-email`] ? invitee.email : ""}
                                    arrow
                                    disableHoverListener={!isOverflowed[`${invitee.email}-email`]}
                                >
                                    <Typography
                                        component="span"
                                        ref={(el) => {
                                            if (!textRefs.current[invitee.email]){
                                                textRefs.current[invitee.email] = { email: null, username: null };
                                            }
                                            textRefs.current[invitee.email].email = el;
                                        }}
                                        variant="body1"
                                        sx={{
                                            fontSize: '11px',
                                            color: '#555555',
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            width: '100%'
                                        }}
                                    >
                                        {invitee?.email}
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid
                                item
                                xs={1}
                                md={1}
                                justifyItems="right"
                                justifyContent="right"
                                alignItems="center"
                            >
                                {(invitee?.is_documents_uploaded || invitee?.message) &&(
                                    <IconButton
                                        onClick={() => getMedia(invitee)}
                                        sx={{
                                            fontSize: '11px',
                                            textTransform: 'capitalize',
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            width: '24px',
                                            height: '24px',
                                            textAlign: 'right',
                                            background:
                                                '#e5ebf3 0% 0% no-repeat padding-box'
                                        }}
                                    >
                                        <ArrowForwardIcon
                                            sx={{
                                                color: 'primary.light',
                                                fontSize: '16px'
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                ))
            ) : (
                <MediaDisplay
                    media={media[0]?.document_urls}
                    selectedInvitee={selectedInvitee}
                    back={() => setShowMedia(false)}
                />
            )}
        </>
    )
}
export { ManageInviteeUploads }
