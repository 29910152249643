import { Box } from '@mui/system'

function PreviewVideo(props: any) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                height: '60vh',
                overflow: 'hidden'
              }}
        >
            <video width="100%" controls style={{width:'auto',height: '100%', maxHeight: '100%',objectFit:'contain'}} >
                <source src={props?.url} />
            </video>
        </Box>
    )
}

export { PreviewVideo }
