/* eslint-disable no-console */
import { SyntheticEvent, useState, useEffect } from 'react'

import { Box, Divider, Tab, Tabs, CircularProgress } from '@mui/material'

import { ManageGroupUploads } from './Group'
import { ManageInviteeUploads } from './Invitees'
import NoDataLabel from '../../../components/Shared/Label/NoDataLabel'
import { TabPanel } from '../../../components/Shared/Tabs/TabPanel'
import {eventService} from '../../../services/event.service'

function ManageUploads(props: any) {
    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(true);
    const [eventInvites, setEventInvites] = useState<any>()

    const getEventInvites = async () => {
        setLoading(true);
        await eventService.getEventInvites(props?.event?.event_id)
            .then((response: any) => {
                setEventInvites(response.data)
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getEventInvites()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{
                    '.Mui-selected': {
                        color: 'primary.light',
                        font: 'normal normal medium 16px/20px Inter'
                    }
                }}
                TabIndicatorProps={{
                    color: 'primary.light'
                }}
            >
                <Tab
                    label="Invitees"
                    sx={{ width: '50%', textTransform: 'capitalize' }}
                />
            </Tabs>
            <Divider />

            <Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%" paddingTop={3}>
                        <CircularProgress />
                    </Box>
                    ) : (
                    <TabPanel value={value} index={0}>
                        {props?.eventInvites?.invites?.length > 0 ? (
                            <ManageInviteeUploads
                                event={props?.event}
                                eventInvites={eventInvites}
                                updateEventInvitesCallBack={props?.updateEventInvitesCallBack}
                            />
                        ) : (
                            <NoDataLabel />
                        )}
                    </TabPanel>
                )}
                <TabPanel value={value} index={1}>
                    {props?.eventGroups?.length > 0 ? (
                        <ManageGroupUploads
                            event={props?.event}
                            eventInvites={props?.eventInvites}
                        />
                    ) : (
                        <NoDataLabel />
                    )}
                </TabPanel>
            </Box>
        </Box>
    )
}
export { ManageUploads }
