import { useEffect, useState } from 'react'

import { useAuth } from '@clerk/clerk-react'
import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useParams } from 'react-router-dom'

import { HeaderCard } from './HeaderCard'
import { UploadMedia } from './UploadMedia'
import Loader from '../../components/Shared/Loader'
import { eventService } from '../../services/event.service'

function Upload() {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { isSignedIn } = useAuth()
    const { eventId } = useParams()
    const [eventDetails, setEventDetails] = useState<any>()

    const getEventDetailsById = async () => {
        setErrorMessage(null)
        setIsLoading(true)
        try {
            const response = await eventService.getEventById(eventId)
            setEventDetails(response.data)
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false)
            if (error?.response?.status === 404) {
                setErrorMessage('404')
            } else {
                setErrorMessage('Failed to load event details')
            }
        }
    };

    const loadData = async () => {
        await Promise.all([getEventDetailsById()])
        setIsLoading(false)
    };

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true)
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <>
            {isLoading && isSignedIn ? (
                <Loader />
            ) : errorMessage === '404' ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: 'gray'
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Ooops... This Event is No Longer Available
                    </Typography>
                    <Typography variant="body1">
                        The event you’re trying to access has been deleted by the creator.
                    </Typography>
                </Box>
            ) : (
                <Box sx={{ width: '100%' }}>
                    <Container
                        sx={{ padding: { xs: '0', sm: '2% 10% !important' } }}
                    >
                        {eventDetails && (
                            <>
                                <HeaderCard event={eventDetails} />
                                <UploadMedia event={eventDetails} />
                            </>
                        )}
                    </Container>
                </Box>
            )}
        </>
    )
}

export { Upload }
