import { useEffect, useState, useCallback } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'

import EventDetailsCard from '../../components/Shared/Cards/EventDetailsCard'
import Loader from '../../components/Shared/Loader'
import {eventService} from '../../services/event.service'


export default function Index() {
    const { isSignedIn } = useAuth()
    const { user } = useUser()
    const [myMemeroies, setMyMemeroies] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    const getMyMemories = useCallback(async () => {
        try {
            const memories = await eventService.getUserMemories(
                user?.primaryEmailAddress?.emailAddress as string
            )
            setMyMemeroies(memories.data)
            setIsLoading(false)
        } catch (error) {
            throw new Error
        }
    }, [user?.primaryEmailAddress?.emailAddress])


    useEffect(() => {
        if (isSignedIn) {
            getMyMemories()
        }
    },[getMyMemories, isSignedIn])
    return (
        <Container sx={{ width: '100%', mt: 2 }}>
            {isLoading && isSignedIn && (
                <>
                    <Loader />
                </>
            )}
             {!isLoading && isSignedIn && (
                <Box sx={{ width: '100%', my: 3 }}>
                    <Grid container spacing={2}>
                        {myMemeroies?.map((event: any) => (
                            <EventDetailsCard
                                key={event.event_id}
                                eventName={event.name}
                                eventType={event.event_type}
                                eventImageUrl={event.event_image_url}
                                eventDate={event?.event_date}
                                showEventDate={false}
                                redirectUrl={`/memories-collection/${event.event_id}`}
                            />
                        ))}

                        {myMemeroies.length === 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    mt: 25,
                                    mx: 'auto',
                                    width: '400',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                        font: 'normal normal normal 13px/16px Inter',
                                        color: '#8D8D8D'
                                    }}
                                >
                                    No Memories to show.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>
            )}
        </Container>
    )
}
