import React, { useState } from 'react';

import { Box, IconButton, Dialog, DialogActions, DialogContent } from '@mui/material';
import ReactEasyCrop from 'react-easy-crop';

import { ReactComponent as AddIcon } from '../../assets/circle-plus-solid-white-icon.svg';
import UserIcon from '../../assets/user.svg';
import CustomLoadingButton from '../../components/Shared/Button/CustomLoadingButton';
import { baseUrlToFile, getCroppedImg } from '../../helpers/eventImageUploaderHelper';

interface EventImageUploaderProps {
  tempEventImage: string;
  eventImageUrl: string;
  handleFileSelect: (files: FileList | null) => void;
}

const EventImageUploader = ({
  tempEventImage,
  eventImageUrl,
  handleFileSelect
}: EventImageUploaderProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState<any>(null);
  const [image, setImage] = useState(tempEventImage || '');
  const [openDialog, setOpenDialog] = useState(false);
  const [fileName, setFileName] = useState('');
  const [zoom, setZoom] = useState(1);
  

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const imgName = file.name;
      setFileName(imgName);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
        setOpenDialog(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropSave = async () => {
    if (croppedArea) {
        const croppedImage = await getCroppedImg(image, croppedArea);
        const file = baseUrlToFile(croppedImage, fileName);
        const fileList = new DataTransfer();
        fileList.items.add(file);
        handleFileSelect(fileList.files);
        setOpenDialog(false);
    }
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          width: '100px',
          height: '100px',
          border: tempEventImage
            ? '2px solid #FFFFFF'
            : eventImageUrl !== ''
            ? '2px solid #FFFFFF'
            : 'none',
          borderRadius: '50%',
          background: `url(${
            tempEventImage ||
            (eventImageUrl !== '' && eventImageUrl !== null ? eventImageUrl : UserIcon)
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '@media (max-width:600px)': {
            width: '80px',  
            height: '80px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            width: '90px',  
            height: '90px' 
          },
          '@media(min_width: 1024)':{
            width: '120px',
            height: '120px'
          }
        }}
      />
      <IconButton
        component="label"
        sx={{
          position: 'absolute',
          marginTop: '-30px',
          marginLeft: '70px',
          backgroundColor: '#7FD14D',
          '&:hover': {
            backgroundColor: '#54a324'
          },
          zIndex: 1000,
          '@media (max-width:600px)': {
            marginTop: '-25px', 
            marginLeft: '60px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            marginTop: '-28px', 
            marginLeft: '65px'
          }
        }}
      >
        <input hidden accept="image/*" type="file" onChange={onImageUpload} />
        <AddIcon />
      </IconButton>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={{
          '@media (max-width:600px)': {
            width: '100%',  
            height: '250px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            width: '400px', 
            height: '320px'
          },
          '@media (min-width: 1024px)': {
            width: '500px',  
            height: '340px' 
          }
        }}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ReactEasyCrop
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <CustomLoadingButton onClick={() => setOpenDialog(false)} name='Cancel' width='110px' sx={{ marginTop: '10px' }} />
          <CustomLoadingButton onClick={handleCropSave} name='Save Crop' width='110px' sx={{ marginTop: '10px' }} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventImageUploader;
