import { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material'

import EventDetailsCard from '../../components/Shared/Cards/EventDetailsCard'

function EventCard({events, handleGetFilteredEvents, past}: any) {
    const [eventItems, setEventItems] = useState(events || []);

    useEffect(() => {
        setEventItems(events);
    }, [events]);

    return (
        <Box sx={{ width: '100%', my: 3 }}>
        <Grid container spacing={2}>
            {eventItems.map((event: any) => (
                <EventDetailsCard
                    key={event?.event_id}
                    eventType={event?.event_type}
                    eventName={event?.name}
                    eventImageUrl={event?.event_image_url}
                    eventDate={event?.event_date}
                    redirectUrl={`/view-event/${event?.event_id}`}
                    pastEvent={past}
                    eventStatus={event?.status}
                    isDelete={event?.is_deleted}
                    eventPublished={event?.is_published}
                    eventId={event?.event_id}
                    handleGetFilteredEvents={handleGetFilteredEvents} 
                />
            ))}
        </Grid>
    </Box>
    )
}

export { EventCard }
