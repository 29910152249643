import React, { FC, createElement, ReactNode } from 'react'

import { Grid } from '@mui/material'

import { OutlinedButtons } from '../Button/OutlinedButtons'

export type classNameType = string
export type childrenType = ReactNode

export interface IFormProps {
    defaultValues?: any
    children?: childrenType
    buttonLabel?: string
    onSubmit?: any
    handleSubmit?: any
    register?: any
    className?: classNameType
    btnLoading: boolean
    submitBtn?: boolean
    spacing?: any
    sx?: any
    disabled?: boolean
}

const Form: FC<IFormProps> = ({
    buttonLabel,
    children,
    onSubmit,
    handleSubmit,
    register,
    btnLoading,
    submitBtn,
    spacing,
    sx,
    disabled,
    ...rest
}) =>(
    <form onSubmit={handleSubmit(onSubmit)} {...rest} noValidate>
        <Grid
            container
            component="main"
            direction="row"
            justifyContent="space-between"
            spacing={spacing || 2}
            sx={sx}
        >
            {Array.isArray(children) ? (
                children.map((child) =>
                    child.props.name ? (
                        <Grid
                            key={child.props.name}
                            item
                            xs={child.props.xs}
                            md={child.props.md}
                        >
                            {createElement(child.type, {
                                ...{
                                    ...child.props,
                                    register,
                                    key: child.props.name
                                }
                            })}
                        </Grid>
                    ) : (
                        <Grid
                            key={child.props.name}
                            item
                            xs={child.props.xs}
                            md={child.props.md}
                        >
                            child
                        </Grid>
                    )
                )
            ) : (
                <Grid item xs={12} md={12}>
                    children
                </Grid>
            )}

            {submitBtn && (
                <Grid item xs={12}>
                    <OutlinedButtons
                        disabled={disabled}
                        width="100%"
                        loading={btnLoading}
                        bgColor="primary.light"
                        text={buttonLabel}
                        onClick={handleSubmit(onSubmit)}
                    />
                </Grid>
            )}
        </Grid>
    </form>
)

export default Form
