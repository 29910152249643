/* eslint-disable no-console */
import { useEffect, useState } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import { Container } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import { CreateEvent } from "./ActiveEvents/CreateEvent"
import { ActiveEvents } from './ActiveEvents/Index'
import { ArchiveEvents } from './ArchiveEvents/Index'
import { PublishedEvents } from './PublishedEvents/Index'
import SwitchButtons from '../../components/Shared/Button/SwitchButtons'
import CustomizedDialogs from '../../components/Shared/Dialogs/CustomizedDialogs'
import Loader from '../../components/Shared/Loader'
import {eventService} from '../../services/event.service'


function Dashboard() {
    const [type, setType] = useState<string>('Active Events')
    const { isSignedIn, isLoaded } = useAuth()
    const { user } = useUser()
    const [events, setEvents] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();

    const options = [
        { label: 'Active Events', value: 'Active Events' },
        { label: 'Published Events', value: 'Published Events' },
        { label: 'Archive Events', value: 'Archive Events' }
    ]

    useEffect(() => {
        if (isLoaded  && !isSignedIn) {
            navigate('/login', { replace: true })
        }
    }, [isSignedIn, isLoaded, navigate])

    
    useEffect(() => {

        const fetchData = async () => {
            if (isSignedIn && user?.primaryEmailAddress?.emailAddress) {
                try{
                    const getEvents = async () => eventService.getEvents(user?.primaryEmailAddress?.emailAddress as string)
                    const eventsResponse = await getEvents();
                    setEvents(eventsResponse.data);

                } catch (error) {
                    console.error("Error fetching events:", error);
                }finally {
                    setIsLoading(false);
                }
            }
        };
        
        fetchData();
    },[isSignedIn, user?.primaryEmailAddress?.emailAddress])

    const handleGetFilteredEvents = (eventId: string) =>{
        setEvents((prevEvents:any) => prevEvents.filter((event: any) => event.event_id !== eventId));
    }

    useEffect(() => {
        const redirectPath = localStorage.getItem('redirectPath')
        if (redirectPath === '/create-event' && !isLoading){
            setOpen(true)
        }
    }, [isLoading])

    const handleClose = () => {
        setOpen(false);
        localStorage.removeItem('redirectPath');
    };
    

    
    if (!isLoaded || !isSignedIn) {
        return <Loader />
    }

    return (
        <Container sx={{ width: '100%', mt: 2 }}>
            {isLoading && isSignedIn && (
                <>
                    <Loader />
                </>
            )}
            {!isLoading && isSignedIn && (
                <>
                    <SwitchButtons options={options} setType={setType} />
                    <>
                        {type === 'Active Events' ? (
                            <ActiveEvents events={events} handleGetFilteredEvents={handleGetFilteredEvents}/>
                        ) : type === 'Archive Events' ? (
                            <ArchiveEvents events={events} />
                        ) : (
                            <PublishedEvents events={events}/>
                        )}
                    </>
                </>
            )}

            <CustomizedDialogs
                open={open}
                onClose={handleClose}
                component={CreateEvent}
            />
        </Container>
    )
}
export { Dashboard }
