import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Typography, Box, IconButton } from '@mui/material';

export const PreviewComponent = ({
  previewFile,
  isMediaFile,
  onClose
}: {
  previewFile: File;
  isMediaFile: (file: File) => boolean;
  onClose: () => void;
}) => (
  <>
    <Card
      sx={{
        boxShadow: 3,
        position: 'relative',
        backgroundColor: 'black' 
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          zIndex: 10,
          color: 'red'
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          width: '100%',
          height: '60vh', 
          overflow: 'hidden'
        }}
      >
        {isMediaFile(previewFile) ? (
          previewFile.type.startsWith('audio/') ? (
            <CardMedia component="audio" controls sx={{ width: '100%' }}>
              <source src={URL.createObjectURL(previewFile)} type={previewFile.type} />
            </CardMedia>
          ) : previewFile.type.startsWith('video/') ? (
            <CardMedia
              component="video"
              controls
              sx={{
                width: 'auto',
                height: '100%', 
                maxHeight: '100%', 
                objectFit: 'contain' 
              }}
            >
              <source src={URL.createObjectURL(previewFile)} type={previewFile.type} />
            </CardMedia>
          ) : previewFile.type.startsWith('image/') ? (
            <CardMedia
              component="img"
              src={URL.createObjectURL(previewFile)}
              alt={previewFile.name}
              sx={{
                width: 'auto', 
                height: '100%', 
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          ) : null
        ) : (
          <Typography variant="body2" color="error">
            File type not supported for preview.
          </Typography>
        )}
      </Box>
    </Card>
  </>
);
