import React, { useEffect, useState } from 'react'
 
import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
 
import { PreviewVideo } from './PreviewVideo'
import PublishedDetails from './PublishedDetails'
import { ReactComponent as PublishSuccessIcon } from '../../../assets/publish-success-icon.svg'
import CustomLoadingButton from '../../../components/Shared/Button/CustomLoadingButton'
import UploadFileButton from '../../../components/Shared/Button/UploadFileButton'
import SelectedFilesCard from '../../../components/Shared/Cards/SelectedFilesCard'
import CustomizedDialogs from '../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../components/Shared/Dialogs/DialogSuccess'
import CustomInput from '../../../components/Shared/Input/CustomInput'
import Form from '../../../components/Shared/Input/Form'
import {handleFileSelectForUploadPublish} from '../../../helpers/uploadMediaHelper'
import {sentGreetingsToRecipient, handleSaveMedia} from '../../../helpers/uploadPublishHelper'
 
const uploadSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email address.')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Enter a valid email address.')
        .required('*Required'),
    name: yup.string()
    .trim()
    .required('*Required'),
    message: yup
    .string()
    .trim()
  });
 
// eslint-disable-next-line max-lines-per-function
function PublishEvent(props: any) {
    const [fileList, setFileList] = useState<any | null>(null)
    const [isUploadStarted, setUploadStarted] = useState(false)
    const [showUploadFinalVideForm, setShowUploadFinalVideForm] = useState(
        !props?.event?.publish_video_url && !props?.event?.message
    )
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [showResentSuccessDialog, setShowResendSuccessDialog] =
        useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [errorMessage, setErrorMessage] = useState('')

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(uploadSchema)
    })
    const files = fileList ? [...fileList] : []
 
    useEffect(() => {
        if (props?.event) {
            const defaultValues: any = {
                name: props?.event?.recipient_name,
                email: props?.event?.recipient_email,
                message: props?.event?.message
            }
            reset({ ...defaultValues })
    }
    }, [showUploadFinalVideForm, reset, props?.event])
 
    const closeSuccessDialog = () => {
        setShowSuccessDialog(false)
        setShowUploadFinalVideForm(false)
    }

    const handleFilesUpdate = (updatedFiles: File[]) => {
        setSelectedFiles(updatedFiles)
        setFileList(updatedFiles)
    }

    const handleBack= () => {
        setShowUploadFinalVideForm(false)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="h6"
                sx={{
                    font: 'normal normal medium 16px/20px Inter',
                    color: 'custom.gray'
                }}
            >
                Upload & Publish
            </Typography>
            <Divider sx={{ my: 1 }} />
 
            <Typography
                variant="subtitle1"
                sx={{
                    font: 'normal normal normal 13px/16px Inter',
                    color: 'custom.dark',
                    my: 1
                }}
            >
                Review your video here and make any changes if required
            </Typography>
            {showUploadFinalVideForm ? (
                <>
                    <Form
                        buttonLabel="Sign In"
                        register={register}
                        handleSubmit={handleSubmit}
                        onSubmit={handleSubmit((data) => handleSaveMedia(
                            data, 
                            files, 
                            props, 
                            setShowResendSuccessDialog, 
                            setUploadStarted, 
                            setBtnLoading, 
                            setShowSuccessDialog,
                            setErrorMessage
                        ))}
                        btnLoading={btnLoading}
                        spacing={2}
                        sx={{ mt: 2 }}
                    >
                        <CustomInput
                            name="name"
                            type="text"
                            showLabel
                            placeholder="What is recipient name?"
                            error={!!errors.name}
                            xs={12}
                            md={12}
                            register={register}
                            onChange={() => setErrorMessage('')}
                            helperText={errors.name ? '*Required' : ''}
                        />

                        <CustomInput
                            name="email"
                            type="email"
                            showLabel
                            placeholder="What is recipient email?"
                            error={!!errors.message}
                            xs={12}
                            md={12}
                            register={register}
                            onChange={() => setErrorMessage('')}
                            helperText={
                                errors.email 
                                    ? (errors.email.message === 'Enter a valid email address.' ? 'Enter a valid email address.' : '*Required')
                                    :''
                            }
                        />
                        <CustomInput
                            name="message"
                            type="text"
                            showLabel
                            placeholder="Message you want to send?"
                            xs={12}
                            md={12}
                            multiline
                            register={register}
                        />
                    </Form>
                    <Grid
                        container
                        component="main"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Grid item xs={12} sm={12}>
                            <UploadFileButton
                                accept="video/*"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelectForUploadPublish(e, setFileList, setSelectedFiles)}
                            />
                            {props?.event?.publish_video_url ? (
                                <SelectedFilesCard
                                files={[props?.event?.publish_video_url]}
                                isUploadStarted={isUploadStarted}
                                onFilesUpdate={handleFilesUpdate}
                                event={props}
                                setShowPreview={() => setShowPreview(true)}
                                />
                            ): (
                                <SelectedFilesCard
                                    files={selectedFiles}
                                    isUploadStarted={isUploadStarted}
                                    onFilesUpdate={handleFilesUpdate}

                            />)}

                            {errorMessage && (
                                    <Typography color="#d32f2f" fontSize= "0.8571428571428571rem" fontWeight= '400' style={{ marginTop: '8px' }}>
                                        {errorMessage}
                                    </Typography>
                                )}
                        </Grid>


                        <Grid container spacing={1} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <Box
                                    display="flex" justifyContent={props?.event?.publish_video_url || props?.event?.message ? "space-between" : "end"} alignItems="center" sx={{ minWidth: '100%' }}
                                    >
                                {(props?.event?.publish_video_url || props?.event?.message) ? (
                                <Button
                                    variant='outlined'
                                    startIcon={<ArrowBackIcon />}
                                    sx={{ backgroundColor: 'primary.light',
                                        color: 'white',
                                        marginTop: '30px',
                                        textTransform: 'capitalize',
                                        fontSize: '13px',
                                        width: '80px',
                                        height: '40px',
                                        px: 2,
                                        '&:hover': {
                                            backgroundColor: 'primary.dark'
                                        }
                                    }}
                                    onClick={handleBack}
                                >
                                    Back
                            </Button>): null}
                            
                            <CustomLoadingButton
                                onClick={handleSubmit((data) => handleSaveMedia(data, 
                                    files, 
                                    props, 
                                    setShowResendSuccessDialog, 
                                    setUploadStarted, 
                                    setBtnLoading, 
                                    setShowSuccessDialog,
                                    setErrorMessage
                                ))}
                                loading={btnLoading}
                                name="Submit"
                                width="auto"
                            />
                        </Box>
                       
                    </Grid>

                </Grid>
                        
                </Grid>
                </>
            ) : (
                <PublishedDetails
                    sendGreetings={() => sentGreetingsToRecipient(props?.event.event_id, setShowResendSuccessDialog)}
                    setShowPreview={() => setShowPreview(true)}
                    setShowUploadFinalVideForm={() =>
                        setShowUploadFinalVideForm(true)
                    }
                    event={props}
                />
            )}
 
            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={closeSuccessDialog}
                header="File Published Successfully!"
                message="Your surprise is on its way."
                continueBtn="View Publish Video"
                icon={PublishSuccessIcon}
                component={DialogSuccess}
            />

            <CustomizedDialogs
                open={showResentSuccessDialog}
                onClose={() => setShowResendSuccessDialog(false)}
                header="Your Greeting Sent Successfully!"
                message="Your surprise is on its way."
                continueBtn="Close"
                isClosed
                icon={PublishSuccessIcon}
                component={DialogSuccess}
            /> 

            <CustomizedDialogs
                open={showPreview}
                onClose={() => setShowPreview(false)}
                url={props?.event?.publish_video_url}
                component={PreviewVideo}
            />
        </Box>
    )
}
 
export { PublishEvent }
